import { InstantLoanStateType } from "../../../Reducers/types"
import { Validators } from "../../types"

// FIXME: these fields depend on employment status value

export const employmentDetailsValidators: Validators<InstantLoanStateType["employmentDetails"]> = {
  employmentStatus(value, values) {
    if (!parseInt(value)) return "Required"
  },
  jobAddressAutocomplete(value, values) {
    if (!value) return "Required"
  },
  jobAddressCivicNumber(value, values) {
    if (!value) return "Required"
  },
  jobAddressStreet(value, values) {
    if (!value) return "Required"
  },
  jobAddressAptNumber(value, values) {},
  jobAddressCity(value, values) {
    if (!value) return "Required"
  },
  jobAddressPostalCode(value, values) {
    if (!value) return "Required"
    if (value.length < 6) return "Must have X9X 9X9 format"
    if (!/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(value))
      return "Must be valid postal code"
  },
  jobAddressStateId(value, values) {},
  jobAddressCountryId(value, values) {},
  jobAddressLatitude(value, values) {},
  jobAddressLongitude(value, values) {},
  timeAtCurrentJob(value, values) {
    if (!Number(value)) return "Required"
  },
  employerName(value, values) {
    if (!value) return "Required"
  },
  companyPhone(value, values) {
    if (!value) return "Required"
    if (value.length < 10) return "Enter a valid 10 digit phone number"
    if (value.includes("+")) return "Do not include the country code (eg. +1)"
    if (!value) return "Enter a valid 10 digit phone number"
  },
  employmentTitle(value, values) {
    if (!value) return "Required"
  },
  startClaimDate(value, values) {
    if (!value) return "Required"
  },
  endClaimDate(value, values) {
    if (!value) return "Required"
    if (+new Date(value || 0) <= +new Date(values.startClaimDate || 0))
      return "End date must be after start date"
  },
}

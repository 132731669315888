import { isCanada, isUS } from "../../../Parsers/Steps/ResidentialDetails"
import { InstantLoanStateType } from "../../../Reducers/types"
import { Validators } from "../../types"

export const residentialDetailsValidators: Validators<InstantLoanStateType["residentialDetails"]> =
  {
    addressAutocomplete(value, values) {
      if (!value && !values.addressCivicNumber) return "Required"
    },
    addressCivicNumber(value, values) {
      if (!value) return "Required"
    },
    addressStreet(value, values) {
      if (!value) return "Required"
    },
    addressAptNumber(value, values) {},
    addressCity(value, values) {
      if (!value) return "Required"
    },
    addressPostalCode(value, values) {
      if (!value) return "Required"
      if (value.length < 6) return "Must have X9X 9X9 format"
      if (!/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(value))
        return "Must be valid postal code"
    },
    addressStateId(value, values) {
      if (!value) {
        return "Required"
      }
    },
    addressCountryId(value, values) {
      if (!value) {
        return "Required"
      }
    },
    addressSinceWhenId(value, values) {
      if (!value) return "Required"
    },
    addressStatusId(value, values) {
      if (!value) return "Required"
    },
    addressResidenceTypeId(value, values) {
      if (!value || !parseInt(value)) return "Required"
    },
    addressOwningStatusId(value, values) {
      if (!value) return "Required"
    },
    addressAmountRentMortgage(value, values) {
      // required only if rent or owning with mortgage
      if (!value && value !== 0) return "Required"
    },
    oldAddressAutocomplete(value, values) {
      if (!value && !values.oldAddressCivicNumber) return "Required"
    },
    oldAddressCivicNumber(value, values) {
      if (!value) return "Required"
    },
    oldAddressStreet(value, values) {
      if (!value) return "Required"
    },
    oldAddressAptNumber(value, values) {},
    oldAddressCity(value, values) {
      if (!value) return "Required"
    },
    oldAddressPostalCode(value, values) {
      if (!value) return "Required"
      if (isUS(values.oldAddressCountryId)) {
        if (!/^\d{5}$/.test(value)) return "Must be valid US postal code"
      } else if (isCanada(values.oldAddressCountryId)) {
        if (value.length < 6) return "Must have X9X 9X9 format"
        if (!/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(value))
          return "Must be valid Canadian postal code"
      }
    },
    oldAddressStateId(value, values) {},
    oldAddressCountryId(value, values) {},
  }

export const hasAddressAptNumber = (addressResidenceType: string) => {
  return (
    addressResidenceType === "4" ||
    addressResidenceType === "5" ||
    addressResidenceType === "6" ||
    addressResidenceType === "10" ||
    addressResidenceType === "11" ||
    addressResidenceType === "12"
  )
}

import { InstantLoanStateType } from "../../../Reducers/types"

export const financialDetailsParser = (
  state: InstantLoanStateType["financialDetails"]
): Partial<Record<keyof InstantLoanStateType["financialDetails"], any>> => ({
  ...state,
  purposeOfLoan: parseInt(state.purposeOfLoan || ""),
  overdueLoans: parseInt(state.overdueLoans || ""),
  creditScoreEstimation: parseInt(state.creditScoreEstimation || ""),
})

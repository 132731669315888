import { SimpleProgressBar } from "@brainfinance/icash-component-library"
import React from "react"
import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { InstantLoanRoutes } from "../../../Helpers/Routes"

export const MobileProgressBar = (props: {
  title: string | undefined
  step: number | undefined
}) => {
  const { state } = React.useContext(InstantLoanContext)

  const step = React.useMemo(() => {
    if (props.step !== undefined) {
      return InstantLoanRoutes.find((route) => route.state?.step === props.step)
    }
    return undefined
  }, [props.step])

  const amount = React.useMemo(() => {
    if (!step) {
      return { completed: 0, goal: 1 }
    }

    let values: any[] = []

    // we expect null/undefined values be skipped, and '' be incomplete
    if (step.path === "personal-details") {
      values = Object.values(state.personalDetails).filter((v) => v != null)
    } else if (step.path === "residential-details") {
      values = Object.values(state.residentialDetails).filter((v) => v != null)
    } else if (step.path === "employment-details") {
      values = Object.values(state.employmentDetails).filter((v) => v != null)
    } else if (step.path === "income-details") {
      values = Object.values(state.incomeDetails).filter((v) => v != null)
    } else if (step.path === "financial-details") {
      values = Object.values(state.financialDetails).filter((v) => v != null)
    } else if (step.path === "reference-details") {
      values = Object.values(state.referenceDetails).filter((v) => v != null)
    }

    const goal = values.length === 0 ? 1 : values.length
    const completed = values.filter((value) => value !== "").length

    return { completed: completed, goal: goal }
  }, [state, step])

  if (!step) {
    return null
  }

  return props.title ? (
    <SimpleProgressBar completed={amount.completed} goal={amount.goal} height={3} />
  ) : null
}
